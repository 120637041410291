import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "icon", "input" ]
	static values = { options: Object }

	onChange(event) {
		const input = event.target
		let url = input.value

		// we start by checking if we are dealing with an URL, if it's not an URL we will try
		// to make it be an URL ... and only if after that is an URL we will try to detect the
		// link to be used
		if (this.#isURL(url)) {
			this.#detectLink()
		} else {
			// we check if there's protocol and if not ... we will assume https
			if (!url.startsWith('http://') || !url.startsWith('https://')) url = `https://${url}`
			if (this.#isURL(url)) {
				input.value = url
				this.#detectLink()
			}
		}
	}

	#isURL(value) {
		try {
			new URL(value)
		} catch {
			return false
		}

		return true
	}

	#detectLink() {
		// no detection needed if we don't have a icon target available
		if (!this.hasInputTarget || !this.hasIconTarget) return

		this.#normalizeLink()
		this.iconTarget.setAttribute("href", `#${this.#findSocialLink(this.inputTarget.value)}`)
	}

	// because sometimes users can insert links that have uppercase case characters for domain
	// we will normalize the domain part before calling detect method
	#normalizeLink() {
		if (!this.hasInputTarget) return

		const value = this.inputTarget.value
		if (value === "") return

		this.inputTarget.value = new URL(value).toString()
	}

	#findSocialLink(url) {
		let match = null
		for (const [key, value] of Object.entries(this.optionsValue)) {
			if (url.match(value.pattern)) match = key
		}
		return match || 'generic'
	}
}

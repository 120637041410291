import { Controller } from "stimulus"

export default class extends Controller {
	static targets = [ "step", "images" ]

	connect() {
		this.#setupImages()
	}

	disconnect() {
		this.close()
	}

	close() {
		this.element.close()
	}

	next(event) {
		const button = event.target
		const currentStep = button.closest('[data-benefits-dialog-target="step"]')
		this.#show(currentStep.nextSibling)
	}

	previous(event) {
		const button = event.target
		const currentStep = button.closest('[data-benefits-dialog-target="step"]')
		this.#show(currentStep.previousSibling)
	}

	#setupImages() {
		const images = Array.from(this.images)
		images.shift()
		images.forEach((image) => image.classList.add("hidden"))
	}

	#show(step) {
		if (!step) return

		const viewport = step.parentNode
		const indexOf = Array.from(viewport.children).indexOf(step)
		step.parentNode.scrollTo(viewport.clientWidth * indexOf, 0)
		this.#showImage(indexOf)
	}

	#showImage(stepIndex) {
		this.images.forEach((image) => {
			image.classList.toggle("hidden", parseInt(image.dataset.step) !== stepIndex + 1)
		})
	}

	get images() {
		return this.imagesTarget.querySelectorAll("[data-step]")
	}
}

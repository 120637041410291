import { Controller } from "stimulus"

export default class extends Controller {
	static values = { target: String }

	open() {
		const target = document.getElementById(this.targetValue)
		if (!target) return

		const isDialog = target instanceof HTMLDialogElement
		if (!isDialog) return

		target.showModal()
	}
}

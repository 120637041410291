// Reload Turbo Frame every N seconds
// <turbo-frame data-controller="reload-frame">
import { Controller } from "stimulus"

export default class extends Controller {
	static values = { seconds: { type: Number, default: 10 } }
	static targets = [ "field" ] // Optional, only reload if field value is nonexistent or empty

	connect() {
		this.timer = setInterval(() => {
			if (!this.hasFieldTarget || (this.hasFieldTarget && this.fieldTarget.value == "")) {
				this.element.reload()
			}
		}, this.secondsValue * 1000);
	}

	disconnect() {
		clearInterval(this.timer)
	}
}
